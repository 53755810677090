<template>

    <div>
        <form>
            <div v-for="child in children"
                :key="child">
                <div class="travel-field">
                    <span class="label children">{{child}}. {{voMsg('quote.child')}}</span>
                    <div class="guest-box">
                        <select :id="'sel'+child"
                            :name="'sel'+child"
                            v-model="childrenModel[child]"
                            @change="onChange($event)"
                            class="form-control custom childrenagesel">
                            <option value="undefined">{{voMsg('quote.unknownage')}}</option>

                            <option v-for="a in ages"
                                :value="a">
                                <!-- <span v-if="a == 0">{{voMsg('quote.notone')}}</span> -->
                                <span v-if="a == 1">{{a}} {{voMsg('quote.year')}}</span>
                                <span v-if="a > 1">{{a}} {{voMsg('quote.years')}}</span>
                            </option>
                        </select>
                        <!--<i class="fa-sharp fa-light fa-angle-down"></i>-->
                    </div>
                </div>
            </div>
        </form>
    </div>



</template>
<script>
    import V from 'voUtils/V.js';

    export default {
    	name: 'childrenage',
    	voVueComponent: 'childrenage',
    	data() {
    		return {
    			childrenModel: []
    		}
    	},
    	props: {
    		children: Number,
    		callback: Function
    	},
    	computed: {
    		ages: function() {
    			var ages = [];
    			for (var i = 1; i <= 15; i++) {
    				ages.push(i);
    			}
    			return ages;
    		}
    	},
    	methods: {
    		convertChildrenModelToString: function() {
    			const values = Object.values(this.childrenModel);
    			const commaSeparatedString = values.join(',');
    			return commaSeparatedString;
    		},
    		onChange(event) {

    			let count = 0
    			let childrenAgesArray = [];
    			let childrenAgesString = '';


    			//console.log('count::', count, 'this.children::', this.children, 'childrenAgesArray::', childrenAgesArray);



    			const commaSeparatedAges = this.convertChildrenModelToString();
    			this.callback(commaSeparatedAges);



    		},
    	},
    	watch: {
    		children: function(newVal, oldVal) {

    			if (newVal > oldVal) {
    				// Kind hinzugefügt
    				this.childrenModel[newVal] = 'undefined';
    			} else if (newVal < oldVal) {
    				// Kind entfernt
    				delete this.childrenModel[oldVal];
    			}

    			const commaSeparatedAges = this.convertChildrenModelToString();
    			//console.log('commaSeparatedAges', commaSeparatedAges)
    			this.callback(commaSeparatedAges);
    		}
    	}

    }
</script>