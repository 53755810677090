<template>

    <voffice-popup-box :trigger-id="triggerId"
        class="full-xxs"
        :apply-label="applyLabel"
        @closed="onClosed">

        <table class="guestbox">

            <tr v-for="(l,what) in sections"
                class="gb-row">

                <td class="gb-td label">
                    <label>{{getFieldLabel(what)}}</label>

                    <div class="age"
                        v-if="what === 'adults'">{{ voMsg('tpl.adults.age') }}</div>
                    <div class="age"
                        v-if="what === 'children'">{{ voMsg('tpl.children.age') }}</div>
                    <div class="age"
                        v-if="what === 'babys'">{{ voMsg('tpl.baby.age') }}</div>

                </td>
                <td class="gb-td">

                    <button class="btn btn-default"
                        :disabled="!canDecr(what)"
                        @click="inc(what,-1)"
                        type="button">-</button>

                </td>
                <td class="gb-td">

                    <button class="btn btn-default"
                        :disabled="!canInc(what)"
                        @click="inc(what,1)"
                        type="button">+</button>


                </td>
            </tr>

        </table>


    </voffice-popup-box>



</template>

<script>
    import vofficePopupBox from './PopupBox.vue';
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'voffice-guestbox',
    	components: {
    		vofficePopupBox
    	},
    	voMsgCodes: [
    		'pet.pl',
    		'adult.pl',
    		'child.pl',
    		'baby.pl'
    	],
    	props: {
    		maxValues: Object,
    		minValues: Object,
    		searchData: Object,
    		showDogCountInSearch: Boolean,
    		triggerId: String,
    		applyLabel: String,
    		childrenWelcome: Boolean,
    		petsWelcome: Boolean
    	},
    	computed: {
    		sections: function() {
    			var res = {
    				'adults': 'adult'
    			};
    			if (this.childrenWelcome) {
    				Object.assign(res, {
    					'children': 'child',
    					'babys': 'baby'
    				});
    			}
    			if (this.petsWelcome) {
    				res.petsCount = 'pet';
    			}

    			return res;

    		}
    	},
    	methods: {
    		onClosed: function() {
    			this.$emit('closed');
    		},

    		getFieldLabel: function(what) {
    			var amount = this.searchData[what] || 0;
    			return amount + ' ' + this.voMsgPl(this.sections[what] + '.pl', amount);
    		},
    		inc: function(what, step) {
    			this.searchData[what] = ((this.searchData[what] || 0) - 0) + step;
    			if (what == 'petsCount') {
    				this.searchData.pets = this.searchData[what] > 0;
    			}

    		},
    		canInc: function(what) {
    			var amount = ((this.searchData[what] || 0) - 0);
    			var max = this.maxValues && this.maxValues[what];
    			if (max != undefined && amount >= max) {
    				return false;
    			}
    			return true;
    		},
    		canDecr: function(what) {
    			var amount = ((this.searchData[what] || 0) - 0);
    			var min = this.minValues && this.minValues[what];
    			if (min == undefined) {
    				min = what == 'adults' ? 1 : 0
    			}

    			if (min != undefined && amount <= min) {
    				return false;
    			}
    			return true;
    		},

    	}

    };
</script>

<style scoped>
    .guestbox {
    	margin: 0 auto;
    	line-height: 46px;
    	text-align: left;
    	font-size: 15px;
    	color: #676767;
    }


    .gb-td {
    	padding: 8px 12px;
    }

    .gb-td.label {
    	line-height: 1;
    }

    .age {
    	font-size: 14px;
    	padding-top: 5px;
    }

    .btn {
    	width: 48px;
    	height: 48px;
    	line-height: 1;
    	border-radius: 50%;
    	font-size: 30px;
    	padding: 4px;
    }

    .label {
    	white-space: nowrap;
    }

    @media (max-width:768px) {}
</style>